@use '@angular/material' as mat;
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif'
);
// Create the theme
$theme: mat.m2-define-light-theme((
  typography: $custom-typography,
));


@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';
/*
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';
*/
// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';


// Include the theme styles
@include mat.all-component-themes($theme);
// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

html,
body {
  height: 100%;
  margin: 0;
  --mdc-checkbox-state-layer-size: 32px;
  --mdc-checkbox-state-size: 32px;
}


.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 20px;
}


.soortFilter ul{
  border:1px solid #e5e5e5;
  max-height: 500px !important;
}
//ALLE INPUTS BORDER EN HEIGHT SET
input[nbinput]:not(textarea) {
  height: 36px !important;
  border: 1px solid #c3c3c3 !important;
}
//ALLE SELECTS BORDER EN HEIGHT SET
nb-select{
  min-height:38px !important;
  height: 38px !important;
  .select-button {
    min-height: 38px !important;
    height: 38px !important;
    border: 1px solid #c3c3c3 !important;
    display: flex !important;
    align-items: center !important;
  }
}
//ALLE TEXTAREAS BORDER EN HEIGHT SET
textarea{
  border:1px solid #c3c3c3 !important;
}



.nb-theme-default nb-sidebar.compacted {
  width: 3.5rem !important;
}
nb-menu .menu-item a {
  padding: 9px 14px; // Adjust padding as needed
}
nb-menu .menu-item:nth-child(2) > a[title*="Projecten"] {
  padding: 15px 14px;
  font-size: 16px;
  font-weight: 600;  /* Make it slightly bolder */
}
//SPECIALE CSS VOOR 5 LAATST BEKEKEN PROJECTEN _ TARGET HET BOLLETJE IN DE TITEL
nb-menu .menu-item a[title*="•"] {
  padding: 5px 14px !important;
}
nb-menu .menu-group {
  padding: 10px 16px; // Adjust padding as needed
}
nb-sidebar{
  max-width: 200px !important;
  .main-container{
    max-width: 200px !important;
  }
  nb-badge{
    white-space: pre-line !important;
  }
  .menu-title{
    white-space: pre-line;
  }
}
nb-select .placeholder{
  opacity:1 !important;
}

mat-tab-header{
  background-color: aliceblue !important;
}

::ng-deep .bigPanelKopieerSelect div.mat-mdc-select-panel {
  font-size: 16px;
  max-height: 1000px; /* Adjust the maximum height of the panel */
  padding: 12px 0; /* Adjust the padding of the panel */
}
.bigPanelKopieerSelect {
  max-height: 1000px !important;
}
